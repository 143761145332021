import { render, cloneVNode, type AppContext } from 'vue';
import { autoPlacement } from '@floating-ui/core';

type VueRendererOptions = {
  onHide?: () => void;
};

class VueRenderer {
  readonly id: string;

  private readonly component: Component;

  readonly teleportElement: HTMLElement;

  private isRenderer = false;
  private vnode: undefined | ReturnType<typeof h>;
  private sideEffects: (() => void)[] = [];
  private onHide: VueRendererOptions['onHide'];

  constructor(component: Component, options: VueRendererOptions = {}) {
    this.id = useUniqueId('custom-toast');
    this.component = markRaw(component);
    this.teleportElement = document.createElement('div');
    this.teleportElement.id = this.id;
    this.teleportElement.classList.add('z-50');
    this.teleportElement.style.position = 'fixed';
    this.onHide = options?.onHide || (() => {});
  }

  updateProps(props: Record<string, any> = {}): void {
    if (this.vnode) {
      render(cloneVNode(this.vnode, props), this.teleportElement);
    }
  }

  destroy(): void {
    this.teleportElement.remove();
    this.sideEffects.forEach(fn => fn());
    this.sideEffects = [];
    this.isRenderer = false;
    this.vnode = undefined;
  }

  hide() {
    this.teleportElement.style.visibility = 'hidden';
    this.onHide?.();
  }

  show() {
    this.teleportElement.style.visibility = 'visible';
  }

  render(props?: Record<string, any>, parentCtx?: AppContext): void {
    if (this.isRenderer) {
      this.updateProps(props);
      this.show();
      return;
    }
    this.vnode = h(this.component, {
      ...(props || {}),
      onClose: () => this.hide()
    });
    if (parentCtx) {
      this.vnode.appContext = parentCtx;
    }

    this.sideEffects.push(
      onClickOutside(this.teleportElement, () => {
        this.hide();
      }),
      onKeyStroke('Escape', () => {
        this.hide();
      })
    );
    render(this.vnode, this.teleportElement);
    document.body.append(this.teleportElement);
    this.isRenderer = true;
  }
}

const updatePosition = (el: HTMLElement, parentEl: MaybeRef<HTMLElement | undefined>) => {
  createLazyToast(el, parentEl, {
    placement: 'bottom-start',
    middleware: [autoPlacement()]
  });
};

type TCreateCustomToast = {
  onCreateToast(parentEl: MaybeRef<HTMLElement | undefined>, props: Record<string, any>): void;
  destroyComponent(): void;
};

const useCreateCustomToast = (toastElem: Component, options?: VueRendererOptions): TCreateCustomToast => {
  const nuxtApp = useNuxtApp();
  if (nuxtApp.ssrContext) {
    return {
      onCreateToast: () => undefined,
      destroyComponent: () => {}
    };
  }

  const component = new VueRenderer(toastElem, options);

  const destroyComponent: TCreateCustomToast['destroyComponent'] = () => {
    if (component) {
      component.destroy();
    }
  };

  const onCreateToast: TCreateCustomToast['onCreateToast'] = (parentEl, props) => {
    component.render(props, nuxtApp.vueApp._context);
    requestAnimationFrame(() => {
      updatePosition(component.teleportElement, parentEl);
    });
  };

  tryOnScopeDispose(destroyComponent);

  return {
    onCreateToast,
    destroyComponent
  };
};

export default useCreateCustomToast;
