<template>
  <div class="flex flex-col h-full flex-1 overflow-auto max-w-full">
    <common-room-chat-list v-if="isAvailable" />
  </div>
</template>

<script setup lang="ts">
  const { isShowChat } = useCurrentRoomPermissions();

  let isAvailable = true;
  if (!isShowChat.value) {
    isAvailable = false;
    await useRoomRedirect();
  }

  useSeoMeta({
    title: 'Chat'
  });
</script>
