<template>
  <div class="bg-card">
    <transition-group name="fade">
      <common-room-chat-message-form
        v-show="!chatStore.activeSelect"
        :ref="chatStore.setCoreFormRef"
        key="form"
        @success="onSuccessSubmit"
      />
      <div
        v-if="chatStore.activeSelect"
        key="actions"
        class="text-center py-4"
      >
        <ui-button
          text="Delete"
          icon-left="delete"
          variant="error-link"
          icon-extend
          hide-space
          @click="chatStore.onDeleteMessages"
        />
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
  const chatStore = useChatStore();

  const onSuccessSubmit = () => {
    nextTick(() => {
      chatStore.coreFormRef?.onFocusMessage();
    });
  };
</script>
