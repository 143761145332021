<template>
  <div class="flex gap-3">
    <div class="w-16 h-16 flex items-center justify-center rounded-xl border border-gray-100 relative">
      <template v-if="source.progress === progress.Upload">
        <ui-spinner class="!w-12 !h-12 text-cyan-800 absolute top-2 lef-2" />
        <ui-button
          variant="error-link"
          size="xl-icon"
          icon-left="close"
          @click="chatStore.removeMessageProcessAttachment(messageId, source.uid)"
        />
      </template>
      <template v-if="source.progress === progress.Error">
        <ui-button
          v-tooltip="$t('Retry')"
          variant="error-link"
          size="xl-icon"
          icon-left="refresh"
          @click="chatStore.retryUploadAttachment(messageId, source.uid)"
        />
      </template>
    </div>
    <common-room-chat-message-attachment-info
      :size="source.file.size"
      :name="source.file.name"
    >
      <p
        v-if="source.progress === progress.Error"
        class="text-error text-sm flex gap-1 items-center"
      >
        {{ $t('Upload Error') }}
        <ui-button
          variant="error-link"
          class="text-sm"
          size="sm-icon"
          hide-space
          icon-right="close"
          @click="onRemove"
        />
      </p>
    </common-room-chat-message-attachment-info>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    messageId: number;
    source: MessageBufferAttachment;
  };
  const props = defineProps<Props>();
  const chatStore = useChatStore();
  const progress = MessageAttachmentProgress;

  const onRemove = () => {
    chatStore.removeMessageProcessAttachment(props.messageId, props.source.uid);
    if (
      !chatStore.messagesAttachments[props.messageId].length &&
      chatStore.checkMessageIsEmptyWithId(props.messageId)
    ) {
      chatStore.onDeleteMessageReq(props.messageId);
    }
  };
</script>
