<template>
  <div class="flex flex-col gap-4 py-2 px-4 bg-card rounded-sm shadow-nav">
    <suspense>
      <ui-menu-list
        :items="navItems"
        class="relative z-[1] flex-col items-stretch max-w-96 mx-auto w-full"
        @click-nav="$emit('close')"
      >
        <template #item="{ item }">
          <common-room-chat-upload-attachment
            v-if="item.itemKey === 'attachment'"
            v-slot="{ inputId }"
            :message-id="messageId"
          >
            <ui-button
              :for="inputId"
              class="w-full"
              direct="left"
              variant="link"
              text="Add Attachment"
              tag="label"
              hide-space
              icon-extend
              icon-left="add"
            />
          </common-room-chat-upload-attachment>
        </template>
      </ui-menu-list>
    </suspense>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    messageId: number;
    isAuthor: boolean;
  };
  const props = defineProps<Props>();
  defineEmits(['close']);

  const chatStore = useChatStore();
  const roomStore = useRoomStore();
  const localePath = useLocalePath();
  const { copy, isSupported } = useClipboard();

  const baseLinkIconProps: NavLink['linkProps'] = {
    variant: 'link',
    iconExtend: true,
    hideSpace: true
  };

  const navItems = computed<NavLink[]>(() => {
    const message = chatStore.findMessage(props.messageId);
    const items: NavLink[] = [
      {
        linkProps: {
          ...baseLinkIconProps,
          iconLeft: 'reply',
          text: 'Reply',
          onClick: () => chatStore.onSelectReplyMessage(props.messageId)
        }
      }
    ];
    if (isSupported.value) {
      const copyLink = {
        linkProps: {
          ...baseLinkIconProps,
          iconLeft: 'copy_link',
          iconLeftType: 'custom',
          text: 'Copy link',
          onClick: () => {
            const messagePath =
              window.location.origin +
              localePath({
                name: 'dashboard-rooms-roomId-chat',
                params: { roomId: roomStore.roomId },
                query: { to: props.messageId }
              });
            copy(messagePath);
          }
        }
      };
      const copyText = {
        linkProps: {
          ...baseLinkIconProps,
          iconLeft: 'content_copy',
          text: 'Copy text',
          onClick: () => {
            const messageText =
              findDoomMessage(props.messageId)?.querySelector('.js-message-content__body')?.textContent || '';
            copy(messageText);
          }
        }
      };
      items.push(copyLink, copyText);
    }

    if (props.isAuthor) {
      items.push({
        linkProps: {
          ...baseLinkIconProps,
          iconLeft: 'edit',
          text: 'Edit',
          onClick: () => chatStore.onSelectEditMessage(props.messageId)
        }
      });
      if (!message?.tenor_url) {
        items.push({
          itemKey: 'attachment'
        });
      }
    }
    if (props.isAuthor || roomStore.userPermission.DeleteMessages) {
      items.push(
        {
          liClass: 'pb-2 border-b border-card-placeholder-border',
          linkProps: {
            ...baseLinkIconProps,
            iconLeft: 'check',
            text: 'Select',
            onClick: () => chatStore.onSelectMessages(props.messageId)
          }
        },
        {
          linkProps: {
            ...baseLinkIconProps,
            variant: 'error-link',
            iconLeft: 'delete',
            text: 'Delete',
            onClick: () => chatStore.onDeleteMessage(props.messageId)
          }
        }
      );
    }

    return items;
  });
</script>
