<template>
  <div
    v-if="roomStore.room"
    class="bg-card p-3 rounded rounded-b-none flex items-center gap-2.5 outline-none cursor-pointer"
    tabindex="0"
    @click="goToDetails"
  >
    <ui-button
      v-if="isLg"
      size="sm-icon"
      variant="link"
      rounded="sm"
      class="shrink-0"
      icon-left="chevron_left"
      :to="{ name: 'dashboard-rooms' }"
    />
    <ui-back-link
      v-else
      :to="{ name: 'dashboard-rooms' }"
    />
    <common-room-chat-header-info-room />
    <ui-button
      v-if="chatStore.activeSelect"
      class="ml-auto"
      variant="link"
      hide-space
      text="Done"
      @click.stop="chatStore.resetSelect"
    />
  </div>
</template>

<script setup lang="ts">
  const roomStore = useRoomStore();
  const chatStore = useChatStore();
  const { routerPush } = useRouterLocale();
  const isLg = useIsLessThan('lg');

  const goToDetails = () => {
    routerPush({
      name: 'dashboard-rooms-roomId',
      params: {
        roomId: roomStore.roomId
      }
    });
  };
</script>
