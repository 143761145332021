<template>
  <div
    v-if="content"
    ref="wrapRef"
    v-dompurify-html="content"
  />
</template>

<script setup lang="ts">
  type Props = {
    content?: string;
    isOpenLinkNewWindow?: boolean;
  };
  const props = defineProps<Props>();
  const wrapRef = ref<HTMLElement>();
  const checkInternalLink = useCheckInternalLink();
  const { routerPush } = useRouterLocale();

  const hydrationHtml = () => {
    if (wrapRef.value) {
      const anchors = wrapRef.value.getElementsByTagName('a');
      useEach(anchors, linkEl => {
        if (props.isOpenLinkNewWindow) {
          linkEl.setAttribute('target', '_blank');
          return;
        }
        const target = linkEl.getAttribute('target');
        const internalLink = checkInternalLink(linkEl.getAttribute('href'), target);
        if (internalLink) {
          const handler = (event: Event) => {
            event.preventDefault();
            routerPush(internalLink as any);
          };
          linkEl.addEventListener('click', handler);
          return;
        }
        if (!target) {
          linkEl.setAttribute('target', '_blank');
        }
      });
    }
  };

  watch(
    wrapRef,
    () => {
      nextTick(hydrationHtml);
    },
    {
      immediate: true
    }
  );

  watch(
    () => props.content,
    () => {
      nextTick(hydrationHtml);
    }
  );
</script>
