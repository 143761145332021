const useCheckInternalLink = () => {
  const requestURL = useRequestURL();
  return (url?: string | null, target?: string | null) => {
    if (target === '_blank' || !(url && (url.startsWith(requestURL.origin) || url.startsWith('/')))) {
      return;
    }
    return url.replace(requestURL.origin, '');
  };
};

export default useCheckInternalLink;
